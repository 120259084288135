<template>
  <div>
    <v-dialog max-width="800" :value="showDonationSuggestionForm" @click:outside="showDonationSuggestionForm = false">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn dark icon @click="showDonationSuggestionForm = false"><v-icon class="font-weight-bold">{{icons.mdiClose}}</v-icon></v-btn>
          <v-toolbar-title class="text-h5 white--text">Remaining Donation</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <v-card-title/>
        <v-card-text>
          <v-autocomplete
            outlined
            dense
            clearable
            label="Enter the contact number/receipt number/name to deposit the remain donation"
            placeholder="Enter the contact number/receipt number/name to deposit the remain donation"
            :prepend-inner-icon="icons.mdiMagnify"
            item-value="id"
            :items="donationOptions"
            @update:search-input="suggestDonations"
            @change="redirectToAddInstallment($event)"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" class="text-right">
        <v-row>
          <v-col cols="12" class="pt-0">
            <v-btn outlined class="ml-2 mt-2" :to="{name: 'add-donation', params: {type: 'regular'}}">Regular Donation</v-btn>
            <v-btn outlined class="ml-2 mt-2" :to="{name: 'add-donation', params: {type: 'quick'}}">Quick Donation</v-btn>
            <v-btn outlined class="ml-2 mt-2" @click="showDonationSuggestionForm = true">Remaining Donation</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <donation-list ref="donations" @editing-donation="editDonation" @editing-installment="editInstallment"></donation-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>

  import {mdiPlus, mdiClose} from '@mdi/js';
  import DonationList from "@/components/donation/List";
  import _ from "lodash";

  export default {
    data() {
      return {
        donationOptions: [],
        showDonationSuggestionForm: false,
        icons: {
          mdiPlus,
          mdiClose,
        }
      }
    },
    components: {
      DonationList,
    },
    watch: {
      showDonationSuggestionForm() {
        if (this.showDonationSuggestionForm === false) {
          this.donationOptions = [];
        }
      },
    },
    methods: {
      suggestDonations: _.debounce(function (value) {
        if (value && value.length >= 3 && !this.donationOptions.some(item => item.text === value)) {
          axiosBackend.get('/donations/suggest', {params: {search: value}})
            .then(response => {
              this.donationOptions = response.data.donations.map(item => {
                return {
                  ...item,
                  ...{text: `${item.id} ${item.donor ? `(${item.donor.mobile})` : ''}  ${item.name} ${item.amount} ${item.temple.name} ${item.donation_type.name}` + (item.celebration ? ` (${item.celebration.name})` : '') + ` પેઠે`}
                };
              });
            });
        }
      }, 500),
      editDonation(donation) {
        this.$router.push({name: 'edit-donation', params: {id: donation.id}});
      },
      editInstallment(installment) {
        this.$router.push({name: 'edit-installment', params: {id: installment.id}});
      },
      redirectToAddInstallment(donationID) {
        this.$router.push({name: 'add-installment', params: {id: donationID}});
      },
    },
  }
</script>
